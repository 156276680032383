@each $key, $breakpoint in $breakpoints {
	@media screen and (max-width: $breakpoint) {
		button.p-button.label-#{$key}-none,
		p-button.label-#{$key}-none {
			.p-button-label {
				display: none !important;
			}
			.p-button-icon {
				margin: 0;
			}
		}
	}
}

$btn-bg: color('primary', 'bg');
$btn-hover-bg: color('primary', 'hover-bg');
$btn-text-hover-bg: color('primary', 'transparent-bg');
$btn-text-hover-color: color('primary', 'bg');

$btn-secondary-bg: color('secondary', 'bg');
$btn-secondary-hover-bg: color('secondary', 'hover-bg');
$btn-text-secondary-hover-bg: color('secondary', 'transparent-bg');
$btn-text-secondary-hover-color: color('secondary', 'bg');

$btn-success-bg: color('success', 'bg');
$btn-success-hover-bg: color('success', 'hover-bg');
$btn-text-success-hover-bg: color('success', 'transparent-bg');
$btn-text-success-hover-color: color('success', 'bg');

$btn-info-bg: color('info', 'bg');
$btn-info-hover-bg: color('info', 'hover-bg');
$btn-text-info-hover-bg: color('info', 'transparent-bg');
$btn-text-info-hover-color: color('info', 'bg');

$btn-warning-bg: color('warning', 'bg');
$btn-warning-hover-bg: color('secondary', 'hover-bg');
$btn-text-warning-hover-bg: color('secondary', 'transparent-bg');
$btn-text-warning-hover-color: color('secondary', 'bg');

$btn-help-bg: color('help', 'bg');
$btn-help-hover-bg: color('help', 'hover-bg');
$btn-text-help-hover-bg: color('help', 'transparent-bg');
$btn-text-help-hover-color: color('help', 'bg');

$btn-danger-bg: color('danger', 'bg');
$btn-danger-hover-bg: color('danger', 'hover-bg');
$btn-text-danger-hover-bg: color('danger', 'transparent-bg');
$btn-text-danger-hover-color: color('danger', 'bg');

$btn-text-plain-hover-bg:  color('plain', 'hover-bg');
$btn-text-plain-hover-color: color('plain', 'bg');


.p-togglebutton.p-button.p-toggle-off-primary:not(.p-highlight) {
	background: $btn-bg;
	border-color: $btn-bg;
	color: #fff;
	.p-button-icon {
		color: #fff;
	}
}


.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
	background: $btn-hover-bg;
	border-color: $btn-hover-bg;
	color: #fff;
	.p-button-icon {
		color: #fff;
	}
}


.p-togglebutton.p-button.p-highlight.p-toggle-on-danger {
	background: $btn-danger-bg;
	border-color: $btn-danger-bg;
	color: #fff;
	.p-button-icon {
		color: #fff;
	}
}

.p-togglebutton.p-button:not(.p-disabled).p-highlight.p-toggle-on-danger:hover {
	background: $btn-danger-hover-bg;
	border-color: $btn-danger-hover-bg;
	color: #fff;
	.p-button-icon {
		color: #fff;
	}
}


.button-nowrap .p-button-label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.button-nowrap.p-button {
	max-width: 100%;
}

.p-split-button-text .p-splitbutton > .p-button {
  background-color: transparent;
  color: $btn-bg;
  border-color: transparent;

	&:not(:disabled):hover {
		background: $btn-text-hover-bg !important;
		border-color: transparent;
		color: $btn-text-hover-color !important;
	}
}

.p-buttonset .p-button:only-child {
	border-radius: 3px;
}

.p-button.p-button-text:not(:disabled):hover,
.p-buttonset > .p-button.p-button-text:not(:disabled):hover,
.p-splitbutton > .p-button.p-button-text:not(:disabled):hover {
	background: $btn-text-hover-bg !important;
	border-color: transparent;
	color: $btn-text-hover-color !important;
}

.p-button.p-button-secondary.p-button-text:not(:disabled):hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:not(:disabled):hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):hover {
	background: $btn-text-secondary-hover-bg !important;
	border-color: transparent;
	color: $btn-text-secondary-hover-color !important;
}

.p-button.p-button-success.p-button-text:not(:disabled):hover,
.p-buttonset.p-button-success > .p-button.p-button-text:not(:disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):hover {
	background: $btn-text-success-hover-bg !important;
	border-color: transparent;
	color: $btn-text-success-hover-color !important;
}

.p-button.p-button-info.p-button-text:not(:disabled):hover,
.p-buttonset.p-button-info > .p-button.p-button-text:not(:disabled):hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):hover {
	background: $btn-text-info-hover-bg !important;
	border-color: transparent;
	color: $btn-text-info-hover-color !important;
}

.p-button.p-button-warning.p-button-text:not(:disabled):hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:not(:disabled):hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):hover {
	background: $btn-text-warning-hover-bg !important;
	border-color: transparent;
	color: $btn-text-warning-hover-color !important;
}

.p-button.p-button-help.p-button-text:not(:disabled):hover,
.p-buttonset.p-button-help > .p-button.p-button-text:not(:disabled):hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):hover {
	background: $btn-text-help-hover-bg !important;
	border-color: transparent;
	color: $btn-text-help-hover-color !important;
}

.p-button.p-button-danger.p-button-text:not(:disabled):hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:not(:disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):hover {
	background: $btn-text-danger-hover-bg !important;
	border-color: transparent;
	color: $btn-text-danger-hover-color !important;
}

.p-button.p-button-plain.p-button-text:not(:disabled):hover,
.p-buttonset.p-button-plain > .p-button.p-button-text:not(:disabled):hover,
.p-splitbutton.p-button-plain > .p-button.p-button-text:not(:disabled):hover {
	background: $btn-text-plain-hover-bg !important;
	border-color: transparent;
	color: $btn-text-plain-hover-color !important;
}

.p-button.p-button-icon-only.p-button-rounded.p-button-sm {
	height: 2rem;
	width: 2rem;
	padding: 0;
}

// .p-button.p-button-sm {
// 	font-size: 0.875rem;
// 	padding: 0.4375rem 0.875rem;
// }

.p-button.p-button-xs {
	font-size: 0.875rem;
	padding: 0.1rem 0.2rem;
	min-height: 1.6rem;
}
.p-button.p-button-xs.p-button-icon-only {
	width: 1.5rem;
	padding: 0;
}

.p-splitbutton .p-splitbutton-defaultbutton.p-button.p-button-outlined:enabled:hover {
	border-right: none;
}

.p-dialog .p-dialog-footer .p-splitbutton button {
	margin: 0;
}

.p-button-inline {
	padding: 0;
	line-height: 1.5rem;
}


.p-confirm-dialog-reject {
	background-color: transparent;
	color: #2196F3;
	border: 1px solid;
}

.p-confirm-dialog-reject:enabled:hover {
	background: rgba(33, 150, 243, 0.04);
	color: #2196F3;
	border: 1px solid;
}

.p-button-bold * {
	font-weight: bold;
}

.p-editor-container .p-editor-toolbar.ql-snow button {
	color: #6c757d;
}

.p-button .pi {
	line-height: 1.5rem;
}

.p-radiobutton-label:not(.p-disabled) {
	cursor: pointer;
}

.p-message-close {
	flex-shrink: 0;
}

p-inputnumber .p-button .pi {
	line-height: unset;
}

.p-button.p-button-icon-only {
	width: 2.357rem;
}

.p-button-icon-only::after {
	content: '';
}
