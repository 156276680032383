app-root {
	// display: block;
	// height: 100%;
	// background: var(--surface-c);
}

portail {
	display: block;
	height: 100%;
	position: relative;
}

.layout-content {
	padding-top: $topbar-height;
	height: 100%;
	position: relative;
}

.portail-layout {

}

.portail-layout .app-content {
	transition: padding 250ms;
	padding-left: $sidebar-width;
	background: var(--surface-c);
	height: 100%;
	max-height: 100%;
}

.app-content ui-view > * {
	// display: flex;
	// flex-direction: column;
	// height: 100%;
	// width: 100%;
	// position: relative;
}

.app-content ui-view > * > h1,
.app-content ui-view > * > h2,
.app-content ui-view > * > h3,
.app-content ui-view > * > h4 {
	flex-shrink: 0;
}

.p-breadcrumb {
	border-radius: 0;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	padding: 0.5rem 1rem 0.5rem 2.5rem;
}

.app-menu-toggler {
	position: absolute;
	top: 0;
	right: -2rem;
	background: #fff;
	box-shadow: 3px 2px 4px -1px rgba(0, 0, 0, 0.25);
	display: flex;
	height: 2.75rem;
	width: 2rem;
	align-items: center;
	justify-content: center;
	border-radius: 0 0 5px 0;
}

.portail-content {
	// padding: 1rem;
	max-height: calc(100% - $topbar-height + 10px); /* pourquoi 10px? */
	overflow: auto;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
}

.portail-content > * {
	padding: 1rem;
	// display: block;
	height: 100%;
	// max-height: 100%;
	// border: 1px solid red;
	// overflow-y: auto;
}

.gz-sidebar {
	position: fixed !important;
	height: calc(100% - #{$topbar-height});
	width: $sidebar-width;
	transition: 250ms;
	z-index: 10;
}

selection-contexte a.contexte {
	max-width: 150px;
	padding: 0;
	margin: 1rem;
}

selection-contexte a.contexte:hover {
	box-shadow: 0 5px 10px rgba(0,0,0,.5);
}

selection-contexte a.contexte > p-card,
selection-contexte a.contexte > p-card > .p-card {
	height: 100% !important;

	.p-card-header {
		flex-grow: 1;
		display: flex;
		justify-content: center;
	}
}

selection-contexte a.contexte p-header {
	display: block;
	padding: 1rem ;
}



selection-contexte a.contexte .p-card-content {
	padding: 0;
}


@media screen and (max-width: $md) {
	body {
		.gz-sidebar {
			transform: translateX(-100%);
			box-shadow: none;
		}
		&.mobile-sidebar-visible {
			.gz-sidebar {
				transform: translateX(0);
				box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
			}
		}
	}

	.portail-layout .app-content {
		padding-left: 0;
	}
}

@media screen and (min-width: $md) {
	body {
		.gz-sidebar {
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
		}
		&.sidebar-hidden {
			.gz-sidebar {
				transform: translateX(-100%);
				box-shadow: none;
			}
			.app-content {
				padding-left: 0;
			}
		}
	}
}
