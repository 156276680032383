app-topbar {
	position: fixed;
	top: 0;
	background-color: var(--surface-a);
	width: 100%;
	height: $topbar-height;
	z-index: 1000;
	box-shadow: 0 0 4px rgba(0, 0, 0, .25);
	border-bottom: 1px solid var(--surface-d);
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

app-topbar .menu-button {
	display: none;
	color: var(--text-color);
	width: 70px;
	height: $topbar-height;
	line-height: $topbar-height;
	text-align: center;
	transition: background-color .2s;
	cursor: pointer;
}

app-topbar .header-title {
	margin: -0.25em 0 0;
	flex-grow: 1;
}

app-topbar .header-title h1 {
	font-size: 1.5em;
	margin: 0;
	line-height: initial;
}


@media screen and (max-width:960px) {
	app-topbar .menu-button {
		display: block;
	}
}

app-topbar .menu-button:hover {
	background-color: var(--surface-c);
}

app-topbar .menu-button i {
	font-size: 24px;
	line-height: inherit;
}

app-topbar .logo-small {
	max-width: 35px;
	max-height: 35px;
}


app-topbar .topbar-menu {
	list-style-type: none;
	padding: 0;
	height: 100%;
	margin: 0 0 0 auto;
	display: -ms-flexbox;
	display: flex;
}

app-topbar .topbar-menu>li {
	height: 70px;
	line-height: 70px;
}

app-topbar .topbar-menu>li>a {
	text-decoration: none;
	color: var(--text-color);
	min-width: 120px;
	font-size: 16px;
	display: block;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 68px;
	border-bottom: 2px solid rgba(0, 0, 0, 0);
	transition: border-bottom-color .2s;
	cursor: pointer;
}

app-topbar .topbar-menu>li>a:focus,
app-topbar .topbar-menu>li>a:hover {
	border-bottom-color: var(--primary-color)
}

app-topbar .topbar-menu>li>a:focus {
	z-index: 1;
	outline: 0 none;
	transition: box-shadow .2s;
	box-shadow: inset 0 0 0 .2em #bbdefb;
}

app-topbar .topbar-menu>li.topbar-submenu {
	position: relative
}

app-topbar .topbar-menu>li.topbar-submenu>ul {
	position: absolute;
	-ms-transform-origin: top;
	transform-origin: top;
	top: 70px;
	right: 0;
	width: 275px;
	max-height: 400px;
	background-color: var(--surface-f);
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
	overflow: auto;
	list-style-type: none;
	padding: 1rem;
	margin: 0;
	border-radius: 3px;
}

app-topbar .topbar-menu>li.topbar-submenu>ul>li {
	line-height: 1
}

app-topbar .topbar-menu>li.topbar-submenu>ul>li.topbar-submenu-header {
	display: block;
	color: var(--text-color-secondary);
	font-weight: 600;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 1.5rem 0 1rem;
	font-size: .857rem;
	text-transform: uppercase;
}

app-topbar .topbar-menu>li.topbar-submenu>ul>li.topbar-submenu-header:first-child {
	padding-top: 1rem
}

app-topbar .topbar-menu>li.topbar-submenu>ul a {
	text-decoration: none;
	color: var(--text-color);
	padding: .5rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-radius: 3px;
	cursor: pointer;
}

app-topbar .topbar-menu>li.topbar-submenu>ul a:hover {
	background-color: var(--surface-c);
}

app-topbar .topbar-menu>li.topbar-submenu>ul a span {
	margin-left: .5rem;
}

app-topbar .topbar-menu>li.topbar-submenu>ul a i {
	font-size: 18px;
	color: var(--text-color-secondary);
}

app-topbar .topbar-menu>li.topbar-submenu>ul a img {
	width: 32px;
	margin-right: .5rem;
}

app-topbar .topbar-menu .theme-badge {
	padding: 2px 4px;
	vertical-align: middle;
	border-radius: 3px;
	color: #fff;
	font-weight: 700;
	font-size: 11px;
	position: relative;
	top: -1px;
}

app-topbar .topbar-menu .theme-badge.material {
	background: linear-gradient(180deg, #2196f3, #2196f3);
}

app-topbar .topbar-menu .theme-badge.bootstrap {
	background: linear-gradient(180deg, #563d7c, #966bd8);
}

app-topbar .topbar-menu .theme-badge.darkmode {
	background: linear-gradient(180deg, #141d26, #5a6067);
}
