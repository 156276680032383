p-dropdown {
	display: block;
}

p-dropdown.p-invalid .p-dropdown {
	border: 1px solid ;
}

p-dropdown .p-readonly.p-dropdown,
p-dropdown:hover .p-readonly.p-dropdown,
p-dropdown:hover .p-readonly.p-dropdown.p-focus {
	border-top: 1px solid transparent !important;
	border-left: 1px solid transparent !important;
	border-right: 1px solid transparent !important;
	border-color: #ced4da;
	-ms-user-select: inherit;
	-webkit-user-select: inherit;
	cursor: text;
	user-select: inherit;

	outline: 0 none;
	outline-offset: 0;
	box-shadow: none;

	.p-dropdown-label {
		cursor: text;
	}
}

p-dropdown .p-readonly.p-dropdown .p-dropdown-trigger {
	display: none;
}

.selector-item-icon,
.selector-item-icon img {
	min-width: 1.5rem !important;
	max-width: 1.5rem !important;
	max-height: 1.5rem !important;
}
