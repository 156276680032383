logo-display {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 100%;
	width: 100%;
}

logo-display .logo-print {
	width: 220px;
	max-width: 100%;
}

logo-display img,
.p-fileupload-thumb img {
	max-width: 100%;
	max-height: 100%;
}

.logo-container {
	padding: 10px;
}

.logo-container-preview {
	border: 1px solid #dee2e6;
	border-radius: 3px;
}

.logo-xs {
	width: 2rem;
	max-width: 100%;
}

.logo-sm {
	width: 6rem;
	max-width: 100%;
}

.logo-md {
	width: 10rem;
	max-width: 100%;
}

.logo-xl {
	width: 20rem;
	max-width: 100%;
}

.logo-inline {
	display: flex;
	align-items: center;
	width: 50px;
	min-width: 50px;
	max-width: 50px;
}
