.login-body {
	background: #f8f9fa;
	color: #495057;
}

.login-body .login-wrapper {
	display: flex;
	height: 100vh;
}

.login-body .login-wrapper .login-panel {
	width: 30%;
	height: 100%;
	padding: 40px 10px;
	max-width: 340px;
	margin: auto;
	overflow-y: auto;
}

.login-body .login-wrapper .login-panel .logo {
	max-height: 90px;
	max-width: 230px;
}

.login-body .login-wrapper .login-panel .login-form {
	max-width: 250px;
	height: auto;
	margin: auto;
}

.login-body .login-wrapper .login-panel .login-form > p {
	font-weight: 600;
	margin: 0;
	color: #6c757d;
	margin-bottom: 32px;
}

.login-body .login-wrapper .login-panel .login-form > p > a {
	color: #1976D2;
	cursor: pointer;
}

.login-body .login-wrapper .login-panel .login-form > input {
	width: 85%;
	max-width: 310px;
	margin-bottom: 20px;
}

.login-body .login-wrapper .login-panel .login-form > button {
	width: 85%;
	max-width: 310px;
}

.login-body .login-wrapper .login-panel p {
	font-weight: 600;
	margin: 0;
	color: #6c757d;
}

.login-body .login-wrapper .login-panel p > a {
	color: #1976D2;
}

.login-body .login-wrapper .login-image {
	width: 70%;
	height: 100%;
	display: flex;
	align-items: center;
	// justify-content: space-between;
	flex-direction: column;
	padding: 40px 20px;
}


@media (min-width: 993px) {
	.login-body .login-wrapper .login-image {
		background-size: cover;
		background-repeat: no-repeat;
		/*background-image: url("/assets/images/carbon-800x800.jpg");*/
		background-color: rgb(32, 32, 32);
	}
}

@media (max-width: 992px) {
	.login-body .login-wrapper .login-panel {
		width: 100%;
		z-index: 2;
	}

	.login-body .login-wrapper .login-image {
		display: none;
	}
}
