tr.ristournes:not(.ristournes-empty) > td {
	padding: 0 !important;
}

tr.ristournes:not(.ristournes-empty) > td:first-child {
	border-right: 1px solid #e9ecef;
}

.niveau-total, .niveau-total td,
.niveau-eqip, .niveau-eqip td {
	height: 2.5rem;
}

.niveau-adherent, .niveau-adherent td,
.niveau-agence, .niveau-agence td,
.niveau-filiale, .niveau-filiale td {
	height: 4rem;
}

.niveau-saisie, .niveau-saisie td {
	height: 4rem;
}

.niveau-saisie.niveau-saisie-edit,
.niveau-saisie.niveau-saisie-edit td {
	height: 4rem;
}

tr.double-input.niveau-saisie,
tr.double-input.niveau-saisie td {
	height: 4rem;
}

tr.double-input.niveau-saisie.niveau-saisie-edit,
tr.double-input.niveau-saisie.niveau-saisie-edit td {
	height: 6.3rem;
}

$colDisplayNumberWidth: 120px;
$colInputNumberWidth: 140px;

.table-saisie-header {
	height: 3rem;
}


table-saisie,
table-suivi-ca,
table-suivi-ca-groupe-adherent,
// table-suivi-bfa,
table-saisie-ca-groupe-adherent {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
}

table-saisie,
table-suivi-ca,
table-suivi-ca-groupe-adherent,
table-suivi-bfa,
table-saisie-ca-groupe-adherent {

	// display: block;
	// display: flex;
	// flex-direction: column;
	// height: 100%;
	// width: 100%;
	// position: relative;

	th {
		text-align: center !important;
	}

	.p-treetable-scrollable-body,
	.p-treetable-frozen-view {
		border-right: 1px solid #e9ecef;
	}

	.p-treetable-frozen-view colgroup col:not(:first-child),
	.p-treetable-unfrozen-view colgroup col,
	colgroup col:not(:first-child),
	colgroup col {
		width: $colDisplayNumberWidth;
		min-width: $colDisplayNumberWidth;
		max-width: 200px;
	}

	.col-mois {
		max-width: $colDisplayNumberWidth;
	}

	colgroup col.col-label {
		width: 280px;
	}

	colgroup col.col-total {
		min-width: 140px !important;
		width: 140px !important;
	}

	colgroup col.column-xs {
		width: 75px;
		max-width: 75px;
	}

	colgroup col.column-sm {
		width: 100px;
		max-width: 100px;
	}

	colgroup col.column-md {
		width: 200px;
		max-width: 200px;
	}

	colgroup col.column-lg {
		width: 300px;
		max-width: 300px;
	}
}

table-saisie {
	.p-treetable-frozen-view colgroup col:not(:first-child),
	.p-treetable-unfrozen-view colgroup col,
	colgroup col:not(:first-child),
	colgroup col {
		width: $colInputNumberWidth;
		min-width: $colInputNumberWidth;
	}
	.col-mois {
		max-width: $colInputNumberWidth;
	}
}

/* force scrollable table width based on predefined column width and number of columns, to avoid using a width:auto because chrome... */
@for $i from 1 through 12 {
	table-saisie .table-saisie-cols-#{$i} .p-treetable-scrollable-view.p-treetable-unfrozen-view table {
		width: $i * $colDisplayNumberWidth;
	}
}

table-saisie .p-treetable.p-datatable-gridlines tr th,
table-saisie .p-treetable.p-datatable-gridlines tr td {
	border-width: 1px !important;
}

table-saisie .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
	margin-right: 0;
	width: 1rem;
	height: 0.9rem;
}

table-saisie .p-treetable.p-treetable-sm table:not(.challenge) tr > th:nth-of-type(2),
table-saisie .p-treetable.p-treetable-sm table:not(.challenge) .p-treetable-tbody > tr > td:nth-of-type(2) {
	/*min-width: 200px;*/
}

table-saisie tr.niveau-total,
table-saisie tr.niveau-eqip {
	background: rgba(0, 0, 0, 0.04) !important;
	padding: 0 0.5rem !important;
}
table-saisie tr.niveau-adherent {
	background: rgba(166, 213, 250, 0.25) !important;
}
table-saisie tr.niveau-filiale {
	background: rgba(192, 250, 166, 0.25) !important;
}
table-saisie tr.niveau-agence {
	background: rgba(250, 237, 166, 0.25) !important;
}


.evo-positive {
	/*color: #224a23;*/
	/*color: #C8E6C9;*/
	color: #439446;
}

.evo-negative {
	/*color: #73000c;*/
	/*color: #FFCDD2;*/
	color: #e60017;
}
