
@mixin fontdef($family, $FontType: "Regular", $weight: 400, $path: "./fonts") {
	font-family: "#{$family}";
	src: url("#{$path}/#{$family}-#{$FontType}.woff2") format("woff2"),
		url("#{$path}/#{$family}-#{$FontType}.woff") format("woff");
	font-weight: #{$weight};
	font-style: normal;
}

@font-face {
	@include fontdef("OpenSans", "Regular", 400);
}

@font-face {
	@include fontdef("OpenSans", "SemiBold", 500);
}

@font-face {
	@include fontdef("OpenSans", "Bold", 700);
}

:root {
	// --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
	--font-family: "OpenSans";
	--border-color: #e9ecef;
}

$topbar-height: 50px;
$sidebar-width: 20rem;

$prefix: '';
$separator: "\\:" !default;
$gutter: .5rem !default;

$fieldMargin: 1rem !default;
$fieldLabelMargin: .5rem !default;
$helperTextMargin: .25rem !default;
$spacer: 1rem !default;

$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$xxl: 1480px !default;
$xxxl: 1600px !default;

$breakpoints: (
	'sm': $sm,
	'md': $md,
	'lg': $lg,
	'xl': $xl,
	'xxl': $xxl,
	'xxxl': $xxxl
);

$sizeConstraints:
	10rem,
	15rem,
	20rem,
	30rem,
	40rem,
	50rem,
	60rem,
	70rem,
	80rem,
	90rem,
	100rem
;

$colors: (
	primary: (
		bg: #2196F3,
		hover-bg: #0D89EC,
		transparent-bg: rgba(33, 150, 243, 0.16),
		text: #2196F3,
		btn-text: #FFFFFF,
		message-text: #0D416A,
	),
	secondary: (
		bg: #607D8B,
		hover-bg: #56717D,
		transparent-bg: rgba(96, 125, 139, 0.16),
		text: #607D8B,
		btn-text: #FFFFFF,
		message-text: #12171A,
	),
	success: (
		bg: #689F38,
		hover-bg: #5E8F32,
		transparent-bg: rgba(104, 159, 56, 0.16),
		text: #689F38,
		btn-text: #FFFFFF,
		message-text: #224A23,
	),
	info: (
		bg: #0288D1,
		hover-bg: #027ABC,
		transparent-bg: rgba(2, 136, 209, 0.16),
		text: #0288D1,
		btn-text: #FFFFFF,
		message-text: #044868,
	),
	warning: (
		bg: #FBC02D,
		hover-bg: #FAB710,
		transparent-bg: rgba(251, 192, 45, 0.16),
		text: #FBC02D,
		btn-text: #212529,
		message-text: #6D5100,
	),
	help: (
		bg: #9C27B0,
		hover-bg: #8C239E,
		transparent-bg: rgba(156, 39, 176, 0.16),
		text: #9C27B0,
		btn-text: #FFFFFF,
		message-text: #240928,
	),
	danger: (
		bg: #D32F2F,
		hover-bg: #C02929,
		transparent-bg: rgba(211, 47, 47, 0.16),
		text: #D32F2F,
		btn-text: #FFFFFF,
		message-text: #73000C,
	),
	plain: (
		bg: #6C757D,
		hover-bg: #E9ECEF,
		transparent-bg: rgba(233, 236, 239, 0.5),
		text: #6C757D,
		btn-text: #56717D,
		message-text: #1F282D,
	)
);
