dataview-publications .p-dataview-content {
	background: transparent !important;

	.p-dataview-emptymessage {
		text-align: center;
	}
}

.actualite-tuile {
	display: block;
	height: 100%;
	max-height: 500px;
}

.publication-box {
	border-radius: 4px;
	width: 100%;
	min-height: 90px;
	padding: 10px;
	box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	color: #495057;
	background: var(--surface-a);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.publication-dialog-content {
	display: flex;
	flex: 1 1 auto;
	overflow: auto;
}

.publication-box-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
}

.actualite-tuile.publication-en-une > * {
	border-left: 5px solid color('primary', 'bg');
}

.tutoriel-tuile.publication-en-une > * {
	border-left: 5px solid color('danger', 'bg');
}

.publication-box-actions {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
}

.publication-box .publication-resume {
	font-size: 0.85rem;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 5px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 20;
}

.publication-readmore {
	font-weight: 600;
	font-size: 0.85rem;
	flex-shrink: 0;
	position: relative;
}

.publication-readmore::before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	height: 2rem;
	background: linear-gradient(180deg, transparent, #fff);
	top: -2rem;
	bottom: 0;
}

.publication-box-text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.publication-box-main,
.publication-dialog-main {
	height: 100%;
	display: flex;
	flex-direction: column;
	max-width: 65%;
}

.publication-resume img,
.publication-html img {
	max-width: 100%;
}

.publication-title {
	flex-shrink: 0;
}

.publication-dialog-main {
	max-width: 100%;
	p, ul, ol {
		margin: 0;
	}
}

.publication-box-secondary {
	width: 250px;
	min-width: 33%;
	max-width: 33%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.publication-dialog-secondary {
	max-width: 33%;
	// min-width: 33%;
	min-width: 10rem;
	// height: 100%;
	// display: flex;
	// flex-direction: column;
}

.publication-box-secondary media-thumb {
	padding-left: 0.5rem;
}

.publication-box-interact {
	display: flex;
	justify-content: space-between;
}

.commentaires-overlaypanel-container {
	max-height: 300px;
	overflow-y: auto;
}

.commentaires-overlaypanel .commentaire {
	background: var(--surface-c);
	border-radius: 5px;
	padding: 0.5rem;
	box-shadow: 0 2px 2px rgba(0,0,0,0.2);
}

.commentaires-overlaypanel .commentaire:not(:first-child) {
	margin-top: 1rem;
}

.publication-dialog .p-dialog-content {
	padding: 0;
	overflow: hidden;
}

publication-view-dialog {
	position: relative;
	display: block;
	max-height: 100%;
	min-height: 100%;
}

.publication-view-dialog-container .p-dialog-header {
	padding: 0.75rem 1.5rem;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	h1 {
		margin: 0;
	}
}

.publication-view-dialog-container {
	@include absolute-full;
}

.publication-view-dialog-container actualite-view,
.publication-view-dialog-container outil-view,
.publication-view-dialog-container tutoriel-view {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	// position: absolute;
	// top: 4.5rem;
	padding-top: 5px;
	bottom: 0;
	left: 0;
	right: 0;
}

.publication-view-dialog-container .p-dialog-footer {
	border-top: 1px solid rgba(0,0,0,0.1);
}

.pieces-jointes {
	word-break: break-word;
	flex-shrink: 0;
}

.emoticone-selector {
	min-width: inherit !important;
	max-width: 8rem !important;
}

.emoticone-selector	li {
	float: left !important;
}

.emoticone-selector a {
	padding: 0.5rem 0.25rem !important;
}

