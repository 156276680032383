@import 'primeflex/primeflex';
// @import 'primeflex/src/_mixins';
// @import 'primeflex/src/_grid';
// @import 'primeflex/src/_colors';
// @import 'primeflex/src/_formlayout';
// @import 'primeflex/src/_display';
// @import 'primeflex/src/_typography';
// @import 'primeflex/src/_flexbox';
// @import 'primeflex/src/_spacing';
// @import 'primeflex/src/_elevation';
// @import 'primeflex/src/_border';
// @import 'primeflex/src/_borderradius';
// @import 'primeflex/src/_size';
// @import 'primeflex/src/_position';
// @import 'primeflex/src/_overflow';
// @import 'primeflex/src/_zindex';
// @import 'primeflex/src/_image';
// @import 'primeflex/src/_userselect';
// @import 'primeflex/src/_liststyle';
// @import 'primeflex/src/_misc';
// @import 'primeflex/src/_transition';
// @import 'primeflex/src/_transform';
// @import 'primeflex/src/_animation';
// @import 'primeflex/src/_utils';

@each $breakpoint, $value in $breakpoints {
  @media screen and (min-width: #{$value}) {
    .#{$breakpoint + $separator} {

      &#{$prefix}col-fixed {
          width: auto;
      }

    }
  }
}

// min/max widths
@each $size in $sizeConstraints {
  .min-w-#{$size} {
    min-width: $size;
  }

  .max-w-#{$size} {
    max-width: $size;
  }

  @each $breakpoint, $value in $breakpoints {
    @media screen and (min-width: #{$value}) {
      .#{$breakpoint + $separator}min-w-#{$size} {
        min-width: $size;
      }

      .#{$breakpoint + $separator}max-w-#{$size} {
        max-width: $size;
      }
    }
  }
}

.min-w-full {
  min-width: 100%;
}

.max-w-full {
  max-width: 100%;
}

// hide breakpoints
@each $breakpoint, $value in $breakpoints {
  @media screen and (max-width: $value) {
    .hide-#{$breakpoint} {
      display: none !important;
    }
    .#{$breakpoint}-width-auto {
      max-width: 100% !important;
      width: auto !important;
    }
  }
}

@function color($color, $type: 'bg') {
  @return map-get(map-get($colors, $color), $type);
}

@each $color-key, $color-value in $colors {
  .p-bg-#{$color-key} {
    background-color: color($color-key, 'bg') !important;
    color: color($color-key, 'btn-text') !important;
  }
  .p-tag.p-bg-#{$color-key} {
    background-color: color($color-key, 'bg') !important;
    color: color($color-key, 'btn-text') !important;
  }
  .text-color-#{$color-key} {
    color: color($color-key, 'text') !important;
  }
}

@each $color-key, $color-value in $colors {
  .p-bg-transparent-#{$color-key} {
    background-color: color($color-key, 'transparent-bg') !important;
  }
  .p-tag.p-bg-transparent-#{$color-key} {
    background-color: color($color-key, 'bg') !important;
    color: color($color-key, 'btn-text') !important;
  }
}
