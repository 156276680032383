.p-panelmenu .p-panelmenu-header.p-highlight > a,
.p-panelmenu .p-panelmenu-header > a {
	background: #fff;
}

.p-panelmenu .p-panelmenu-content {
	padding: 0;
}

.p-panelmenu .p-panelmenu-header > a {
	font-weight: normal;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
	// padding: 0;
}

.p-panelmenu .p-panelmenu-header > a:focus,
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
	box-shadow: none;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
	margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
	margin-right: 0.5rem;
}

gz-panelmenu .p-menuitem-link-has-children {
	background: var(--surface-100);
}


gz-panelmenu .p-menuitem-link-active {
	background: var(--surface-100);
}

gz-panelmenu .p-menuitem-link-active {
	font-weight: bold !important;
}

gz-panelmenusub .p-menuitem .p-menuitem-link {
	padding: 0.75rem 1rem 0.75rem 2rem !important;
}


gz-panelmenu .arrow-right gz-panelmenusub .p-menuitem .p-menuitem-link.p-menuitem-link-has-children+gz-panelmenusub .p-menuitem .p-menuitem-link {
	padding-left: 3rem !important;
}

gz-panelmenu .arrow-left gz-panelmenusub .p-menuitem .p-menuitem-link.p-menuitem-link-has-children+gz-panelmenusub .p-menuitem .p-menuitem-link {
	padding-left: 2.75rem !important;
}

gz-panelmenuitembadge .p-tag {
	display: block;
	padding: 0.1rem 0.4rem;
}

gz-panelmenuitembadge * {
	font-size: 0.75rem !important;
}


.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
	background: #f8f9fa;
	border-color: #dee2e6;
	color: #495057;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
	border-radius: 0;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.p-panelmenu .p-panelmenu-header > a {
	padding: 1rem;
	border: 1px solid #dee2e6;
	border-top-color: rgb(222, 226, 230);
	border-right-color: rgb(222, 226, 230);
	border-bottom-color: rgb(222, 226, 230);
	border-left-color: rgb(222, 226, 230);
	color: #495057;
	border-radius: 3px;
	transition: box-shadow 0.2s;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
	border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
	border-radius: 0;
}

.p-panelmenu a:hover,
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
	border-color: #dee2e6;
	background: #e9ecef;
	color: #495057;
}
