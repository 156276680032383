matrice-form {
	max-width: 100%;
	position:	relative;
}

.table-matrice {
	.cell-input {
		position: relative;
		display: flex;

		.p-input-icon-left, .p-input-icon-right {
			overflow: hidden;
		}

		.p-input-icon-left > i, .p-input-icon-right > i {
			z-index: 1;
		}

		span.p-inputnumber {
			max-width: 100%;

			input {
				max-width: 100%;
			}
		}

	}

}

.table-matrice.ristourne-croisement {

}

.table-matrice-condensed table,
.table-matrice-condensed tr,
.table-matrice-condensed td,
.table-matrice-condensed tr:hover,
.table-matrice-condensed td:hover  {
	background: transparent !important;
	min-width: auto !important;
	border: 0 !important;
	font-weight: normal;
}

.table-matrice-condensed .p-datatable-wrapper {
	display: block !important;
}

.table-matrice-condensed td.table-matrice-col-left {
	padding: 0 0 0 0 !important;
}

.table-matrice-condensed td.table-matrice-col-right {
	padding: 0 0 0 0.25rem!important;
}

.table-matrice-condensed td.table-matrice-col-left > ::after {
	content: ' :';
}
