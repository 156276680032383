.eqip-progress-spinner {
	position: relative;
}

.eqip-progress-spinner-svg {
	-webkit-animation: eqip-progress-spinner-rotate 2s linear infinite;
	animation: eqip-progress-spinner-rotate 2s linear infinite;
	animation-duration: 2s;
	height: 100%;
	-ms-transform-origin: center center;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.eqip-progress-spinner-circle {
	stroke-dasharray: 89,200;
	stroke-dashoffset: 0;
	stroke: #d62d20;
	-webkit-animation: eqip-progress-spinner-dash 1.5s ease-in-out infinite, eqip-progress-spinner-color 6s ease-in-out infinite;
	animation: eqip-progress-spinner-dash 1.5s ease-in-out infinite,eqip-progress-spinner-color 6s ease-in-out infinite;
	stroke-linecap: round;
}

@keyframes eqip-progress-spinner-rotate {
	100% {
		transform: rotate(1turn);
	}
}

@keyframes eqip-progress-spinner-color {
	100%,
	0% {
		stroke: #00a4e9;
	}
	40% {
		stroke: #003f73;
	}
	66% {
		stroke: #f80000;
	}
	80%,
	90% {
		stroke: #5d0000;
	}
}

@keyframes eqip-progress-spinner-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}


.progressbar-sm .p-progressbar {
	height: 1rem;
	font-size: 0.75rem;
}
