.dashboard-box {
	border-radius: 4px;
	width: 100%;
	min-height: 90px;
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-start;
	align-items: top;
	box-shadow: 0 2px 2px rgba(0,0,0,0.2);
	color: #495057;
	background: var(--surface-a);
	height: 100%;
}

.dashboard-box > div {
	width: 100%;
}

a.dashboard-box:hover {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.dashboard-box-text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.dashboard-box-header {
	display: flex;
	align-items: center;
}

.dashboard-box-icon img {
	width: 100%;
}
.dashboard-box-status {
	background: #00a4e9;
	border-radius: 4px;
	padding: 5px;
	text-align: center;
	color: #fff;
	font-weight: bold;
	font-size: 0.8em;
}


eqip-dashboard liste-historique-ajouts-fichiers,
adherent-dashboard liste-historique-ajouts-fichiers {
	.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
		padding: 0.25rem 0.25rem;
	}
	.col-logo {
		width: 25px;
		max-width: 25px;
		min-width: 25px;
	}
}

eqip-dashboard .p-card .p-card-title,
adherent-dashboard .p-card .p-card-title {
	font-size: 1.2rem;
}

eqip-dashboard .p-card .p-card-content,
adherent-dashboard .p-card .p-card-content {
	padding: 0
}
