.creneau-selector {
    border: 1px solid rgb(218, 218, 218);
    width: 100%;
}

.creneau-selector-group-header {
    background: rgb(230, 230, 230);
    cursor: pointer;
}

.creneau-selector-item {
    background: rgb(245, 245, 245);
    padding-left: 0.5rem;
}

.creneau-selector:not(.readonly) .creneau-selector-item:hover, .creneau-selector-item.selected  {
    background: rgb(255, 255, 255);
    cursor: pointer;
}

.creneau-selector-item:not(:first-child) {
    border-top: 1px solid rgb(209, 209, 209);
}