.ql-snow .ql-picker.ql-size,
.ql-snow .ql-picker.ql-header {
	width: auto;
	min-width: 98px;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
	display: inline-block;
	position: relative;
	top: 0.25rem;
}

.ql-align-left {
	text-align: left;
}

.ql-align-center {
	text-align: center;
}

.ql-align-right {
	text-align: right;
}

/* traductions... */
.ql-snow .ql-tooltip[data-mode="link"]::before {
	content: "Url :";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
	content: "Valider"
}
