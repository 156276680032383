/* You can add global styles to this file, and also import other style files */

@import 'styles/_variables';
@import 'styles/primeflex';
@import 'styles/base';
@import 'styles/buttons';
@import 'styles/checkbox';
@import 'styles/dashboard-box';
@import 'styles/dropdown';
@import 'styles/eqip-spinner';
@import 'styles/fileupload';
@import 'styles/grid-xxl';
@import 'styles/list';
@import 'styles/login';
@import 'styles/logo';
@import 'styles/media';
@import 'styles/menu';
@import 'styles/quill';
@import 'styles/number-selector';
@import 'styles/creneau-selectors.scss';
@import 'styles/paragraphes';
@import 'styles/portail';
@import 'styles/publication';
@import 'styles/table';
@import 'styles/table-matrice';
@import 'styles/table-saisie';
@import 'styles/tabs';
@import 'styles/text';
@import 'styles/topbar';
