.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	border-width: 1px 1px 2px 1px;
	border-color: #dee2e6;
}

.p-tabmenu .p-tabmenu-nav,
.p-tabview .p-tabview-nav {
	background: var(--surface-b);
}

.p-tabmenu-nav,
.p-tabview-nav {
	display: block !important;
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	max-width: 100%;
	scrollbar-width: thin;
	width: 100%;

	& > li {
		display: inline-block;
		position: relative;
	}

	& > a.p-tabview-nav-link:focus,
	& > a.p-tabmenu-nav-link:focus,
	a:focus {
		z-index: 0 !important;
		box-shadow: none !important;
	}

	& > .p-highlight::after {
		content: '';
		width: 100%;
		display: block;
		height: 2px;
		bottom: 0;
		position: absolute;
		left: 0;
		background: var(--primary-color);
	}

	& > .p-tabview-ink-bar,
	& > .p-tabmenu-ink-bar {
		display: none !important;
	}
}

.tabview-flex {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	height: 100%;
	position: relative;

	.p-tabview {
		display: flex;
		flex-direction: column;
	}

	.p-tabview-nav-container {
		flex-shrink: 0;
	}

	.p-tabview-panels {
		overflow: auto;
		flex: 1;
	}

	.p-tabview-panel {
		max-height: 100%;
		height: 100%;
	}

	p-tabpanel > .p-tabview-panel > * {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
}


.tabview-no-padding {
	.p-tabview-panels {
		padding: 0;
	}
}

.tabview-no-bg {
	.p-tabview-panels {
		background: none;
	}
}

.tabview-mt-1 {
	.p-tabview-panels {
		margin-top: 1rem;
	}
}